@import 'variables';
.doc-detail {
    background: white;
    margin: 40px auto 10px auto;
    width: 100%;
    max-width: 800px;
    @media #{$screenLG} {
      max-width: 1000px;
    }
    @media #{$screenXS} {
      padding: 10px;
    }
    padding: 0 20px 20px;
    border: 1px solid #f2f2f2;
    border-radius: 5px;
    header {
      display: flex;
      align-items: center;
      padding-bottom: 5px;
      .document-back {
        padding: 0;
        min-width: 0;
        width: 60px;
        height: 60px;
        flex-shrink: 0;
        line-height: 40px;
        border-radius: 50%;
        margin-right: 10px;
        
        mat-icon {
          width: 40px;
          font-size: 40px; 
        }
      }
    
      &.card {
        background: white;
        padding: 10px 0px;
        border-bottom: 1px solid #f2f2f2;
        .group {
          .title {
            margin-bottom: 4px;
            margin-top: 0;
            line-height: 30px;
          }
          .subtitle {
            color: rgba(#062743, .6);
          }
        }
        .end-button {
          button{
            margin-left: 5px;
          }
          .hide {
            display: none;
          }
          .icon-send-back{
            transform: rotate(180deg)
          }
          .mat-icon-button.outline {
            border: 1px solid rgba(black, .1);
            margin-left: 5px;
          }
        }
      }
    
      img {
        margin: 0;
        width: 50px;
        height: 50px;
        border-radius: 100%;
      }
    }
    main {
      article {
        padding: 0px 10px;
        section {
          margin-top: 30px;
          border-bottom: 1px solid #a0a0a0;
          header {
            color: #686868;
          }
          .label{
            color: $accentColor;
            font-size: 12px;
          }
          p{
            margin: 0 0 10px 0 !important;
          }
        }
        .title {
          max-width: 85%;
          height: auto;
        }
  
        .section-group {
          @media (min-width: 1240px) {
            display: flex;
            align-items: center;
          }
  
          section {
            width: 100%;
            &:first-child {
              margin-right: 14px;
            }
            &:nth-child(3) {
              margin-left: 14px;
              @media (max-width: 1240px) {
                margin-left: 0px;
              }
            }
          }
        }
        p.summary {
          line-height: 1.6em;
          text-align: justify;
          max-width: 100%;
          height: auto;
          margin: 10px 10px 30px 10px;
          text-justify: none;
          &.larger{
            font-size: larger;
          }
        }
  
        .upload-list {
          max-height: 215px;
          overflow-y: auto;
          .file-item {
            height: 70px;
            display: flex;
            border-bottom: 1px solid rgba(black, .1);
            padding: 10px 10px 0px 0px;
            align-items: center;
  
            .content {
              flex-grow: 1;
              border-bottom: 2px solid $accentSuperLightColor;
  
              h5 {
                text-overflow: ellipsis;
                max-width: 390px;
                white-space: nowrap;
                overflow: hidden;
                line-height: 28px;
                font-size: 15px;
                margin-top: 5px;
                color: white;
              }
            }
  
            span.progress-message {
              padding: 2px 8px;
              border-radius: 2px;
              margin-right: 6px;
              display: inline-block;
              color: #212121;
        
              &.pending {
                background: #FF8A14;
              }
        
              &.success {
                background: #4e6249;
              }
        
              &.error {
                background: red;
              }
            }
          }
        }
      }
    }
    
  }
  
  