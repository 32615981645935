@use './document-item';
@use './setting-container';
@use './document-detail';
@import '@angular/cdk/overlay-prebuilt.css';
@import "themes/base";
@import url("~normalize.css");
@import 'variables';

html,
body {
  height: 100%;
}

.mat-raised-button,
.mat-button,
.mat-stroked-button,
.mat-flat-button {
  border-radius: 4px;

  &.rounded {
    border-radius: 100px;
  }
}

.mat-form-field .mat-form-field-label {
  height: 30px !important;
}

button {
  outline: none;
}

.mat-toolbar {
  background: white;
  min-height: 50px;
  @include light-drop-shadow;
}

.spacer {
  flex: 1 1 auto;
}

.container.fixed {
  max-width: 100%;
  margin: 0px;
  overflow-y: auto;
}

.mat-datepicker-toggle {
  button.mat-focus-indicator {
    margin-bottom: 7px;
  }
}


.mat-form-field {

  .mat-form-field-label {
    padding: 4px 0;
    height: 20px;
  }

  input.mat-input-element {
    line-height: 25px;
  }

  .mat-select-value {
    line-height: 25px;
  }

  textarea.mat-input-element {
    line-height: 28px;
  }

  .mat-form-field-infix {
    border-top: 0.6em solid transparent
  }
}

.loading-layout {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.cdk-overlay-backdrop.cdk-overlay-dark-backdrop {
  background: rgba($primaryColor, .9);
}


.less-shadow {
  .mat-dialog-container {
    box-shadow: 0 2px 6px rgba(black, .1);
    border-radius: 6px;
  }

  .mat-selection-list {
    outline: none;
  }
}



input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::-moz-selection {
  color: white;
  background: $accentColor;
}

::selection {
  color: white;
  background: $accentColor;
}

.mat-search {
  overflow: hidden;
  flex: 1 1 auto;
  padding: 4px;
  display: flex;

  input {
    margin: 0 10px;
    border-radius: 6px;
    flex-grow: 1;
    color: #333;
    background: rgba(255, 255, 255, 0.7);
    font-size: .8em;
    font-weight: normal;
    padding: 0 14px;
    height: 45px;
    line-height: 45px;
    outline: none;
    border: 1px solid transparent;
    transition: .1s ease;
    max-width: 500px;
    min-width: 50px;

    &:focus {
      background: white;
      box-shadow: 0 1px 2px rgba(black, .05);
      border: 1px solid rgba(black, .05);
    }
  }
}


.common-card {
  border-radius: 6px;
  overflow: hidden;

  box-shadow: 0 1px 2px rgba(black, .1);
  background: white;

  .title {
    color: #062743;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }

  .subtitle {
    color: #8f8f8f;
  }
}

.icon-text {
  margin: 2px 0;
  display: flex;
  align-items: center;

  span {
    margin-left: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }

  &.warn {
    color: #FF8A14;
  }
}

.mat-fab.fixed {
  position: fixed;
  right: 50px;
  bottom: 30px;

  @media #{$screenXS} {
    right: 25px;
    bottom: 20px;
  }
}

.mat-toolbar-multiple-rows {
  z-index: 10;
}

.tree-dialog-panel {
  background: white;

}

body * {
  box-sizing: border-box;
}

.doc-detail {
  .comment {
    .mat-form-field {
      .mat-form-field-wrapper {
        padding: 0;
      }
    }
  }
}

.mat-menu-content {
  &>.user-profile {
    display: none;

    @media #{$screenXS} {
      display: block;
    }
  }

}

.table-container {
  overflow-x: auto;
}

.mat-icon-button.btn-file-action {
  background-color: #4e6249;
  color: white;
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin-left: 10px;

  &.loading {
    background-image: url(/assets/images/loading.svg);
    background-position: center;
    background-size: contain;
    color: transparent;
    background-color: #d4d4d4;
  }

  .mat-button-wrapper {
    mat-icon {
      font-size: 20px;
    }
  }
}

.mat-select-search {
  position: relative;
  padding-top: 50px !important;

  input.mat-filter-input {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border: 0;
    background-color: unset;
    color: gray;
    width: calc(100% + 32px);
    height: 50px;
    padding: 10px 16px;
    border-bottom: 1px solid #b1b1b1;
    box-sizing: border-box;

    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    background: white;
  }

  .mat-select-search-panel {
    display: block;
  }
}

.mat-select-search-panel {
  display: none;
}

.office-select-tree {
  .mat-option {
    .mat-option-text {
      .parent {
        display: none;
      }
    }

    &.parent-visible {
      .mat-option-text {
        .parent {
          display: inline;
          margin-left: -20px;
        }
      }
    }
  }
}


.flex-column {
  flex-direction: column;
  display: flex;
}
.mat-form-field-appearance-legacy{
  .mat-select{
    line-height: 33px;
  }
}
